import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import TextField from '@mui/material/TextField';
import { styled } from '@mui/system';
import Button from '../common/components/Button';
import { MdOutlinePostAdd } from "react-icons/md";
import { MdPreview } from "react-icons/md";
import Dialog from '@mui/material/Dialog';
import MuiDialogContent from '@mui/material/DialogContent';
import * as APIService from "../common/api/index.js";
import URL from "../common/api/ConstantURL";
import AlertMessage from "../common/components/AlertMessage";
import Backdrop from '@mui/material/Backdrop';
import { IoChevronBackCircleSharp } from "react-icons/io5";

const CustomTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#FCB040',
        fontWeight: 'bold'
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#B2BAC2',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#FCB040',
        },
        '&:hover fieldset': {
            borderColor: '#B2BAC2',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#FCB040',
        },
    },
});

const Vitals = () => {
    const [showTodoPopup, setShowTodoPopup] = useState(false);
    const [showResidentValue, setShowResidentValue] = useState(null);
    const [showAdd, setShowAdd] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showButtonState, setShowButtonState] = useState(true);
    const [alertStatus, setAlertStatusBase] = useState("");
    const [allVitalInfoClientList, setAllVitalInfoClientList] = useState([]);
    const [vitalData, setVitalData] = useState({
        date: "",
        time: "",
        leftArmBP: "",
        rightArmBP: "",
        currentHeartRate: "",
        restingHeartRate: "",
        bodyTemp: "",
        bloodSugar: "",
        oxygenLevel: ""
    });

    const navigate = useNavigate();

    useEffect(() => {
        getAllVitalInfoByClientId();
      }, []);
    
      const getAllVitalInfoByClientId = () => {
        setAllVitalInfoClientList([]);
        setLoading(true);
        APIService.getData(URL.getAllVitalInfoByClientId + '?clientId=' + sessionStorage.getItem("ResidentId"))
          .then(responseData => {
            // Handle response data
            if (responseData.code == 200) {
                setAllVitalInfoClientList(responseData.data);
            } else {
                setAlertStatusBase({ message: responseData.message, status: responseData.code, origin: "center" });
            }
            setLoading(false);
          })
          .catch(error => {
            // Handle errors
            setLoading(false);
            setAlertStatusBase({ message: 'Error while fetch vitals of the resident', status: '400', origin: "center" });
          });
      }

    const openTodo = (e, page, vitals) => {
        e.preventDefault();
        if(page == "add") {
            setShowAdd(false);
            setShowButtonState(true);
            var setShowVitals={};

            setShowVitals.date = "";
            setShowVitals.time = "";
            setShowVitals.leftArmBP = "";
            setShowVitals.rightArmBP = "";
            setShowVitals.currentHeartRate = "";
            setShowVitals.restingHeartRate = "";
            setShowVitals.bodyTemp = "";
            setShowVitals.bloodSugar = "";
            setShowVitals.oxygenLevel = "";  
            setVitalData(setShowVitals);      
        }else {
            const parsedData = JSON.parse(vitals.vitalInformation);
            setShowAdd(true);
            var setShowVitals={};

            setShowVitals.date = parsedData.date;
            setShowVitals.time = parsedData.time;
            setShowVitals.leftArmBP = parsedData.leftArmBP;
            setShowVitals.rightArmBP = parsedData.rightArmBP;
            setShowVitals.currentHeartRate = parsedData.currentHeartRate;
            setShowVitals.restingHeartRate = parsedData.restingHeartRate;
            setShowVitals.bodyTemp = parsedData.bodyTemp;
            setShowVitals.bloodSugar = parsedData.bloodSugar;
            setShowVitals.oxygenLevel = parsedData.oxygenLevel;  
            setVitalData(setShowVitals);     
        }
        setShowTodoPopup(true);
    }

    const handleClose = () => {
        setShowTodoPopup(false);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        const data = { caregiverId: sessionStorage.getItem("CaregiverId"), clientId: sessionStorage.getItem("ResidentId"), clientName: sessionStorage.getItem("ResidentName"), vitalInformation: JSON.stringify(vitalData) };
        APIService.postData(URL.addVitalInfoByCaregiver, data)
          .then(responseData => {
            // Handle response data
            setLoading(false);
            if (responseData.code == 200) {
              setShowTodoPopup(false);
              setAlertStatusBase({ message: responseData.message, status: responseData.code, origin: "center" });
              getAllVitalInfoByClientId(); 
            } else {
              setAlertStatusBase({ message: responseData.message, status: responseData.code, origin: "center" });
            }
          })
          .catch(error => {
            // Handle errors
            setLoading(false);
            setAlertStatusBase({ message: 'Error while add vitals', status: '400', origin: "center" });
          });    
    }

    const closeAlert = () => {
        setAlertStatusBase("");
    }

    const updateVitalDetails = (e) => {
        var key = e.target.name;
        var value = e.target.value;
        value = value.replace(/^\s+/, '');
        setVitalData({
            ...vitalData,
            [key]: value
        });

       
        if(key=="date"){
            if(value != "" && vitalData.time != "" && vitalData.leftArmBP != "" && vitalData.rightArmBP != ""){
                setShowButtonState(false);
            }else{
                setShowButtonState(true);
            }
        }else if(key=="time"){
            if(vitalData.date != "" && value != "" && vitalData.leftArmBP != "" && vitalData.rightArmBP != ""){
                setShowButtonState(false);
            }else{
                setShowButtonState(true);
            }
        }else if(key=="leftArmBP"){
            if(vitalData.date != "" && vitalData.time != "" && value != "" && vitalData.rightArmBP != ""){
                setShowButtonState(false);
            }else{
                setShowButtonState(true);
            }
        }else if(key=="rightArmBP"){
            if(vitalData.date != "" && vitalData.time != "" && vitalData.leftArmBP != "" && value != ""){
                setShowButtonState(false);
            }else{
                setShowButtonState(true);
            }
        }
    };

    const handleBack = () =>{
        navigate("/resident-home");
    }

    return (
        <>
            <Header />
            <div className='home-container'>
                <Backdrop
                    sx={{ color: '#ffba30', zIndex: (theme) => theme.zIndex.drawer + 1000 }}
                    open={loading}
                >
                    <img src={require('../assets/btTree.png')} className='loading-img' />
                </Backdrop>
                {alertStatus ? <AlertMessage status={alertStatus.status} message={alertStatus.message} origin={alertStatus.origin} closeAlert={closeAlert} /> : null}
                <div className='home-inner-container' style={{ justifyContent: "flex-start" }}>
                    <div className='heading vitals-heading'> 
                        <IoChevronBackCircleSharp className='vitals-preview-header' onClick={handleBack}/>
                        <span style={{fontWeight:"500", paddingLeft:"0px"}}>{sessionStorage.getItem('ResidentName')}</span>
                        <span></span>
                    </div>
                    <div className='vitals-heading'> 
                        <span></span>
                        <span>Vitals</span>
                        <span></span>
                    </div>

                    <div className='task-container col-11 col-sm-11 col-md-8 col-lg-4 col-xl-4' style={{ height: '650px', overflow: "inherit", cursor:"default", padding:"0px" }}>
                        <div className='title-container vitals-outer-container'>
                            <span className='previous'>Previously Submitted Vitals</span>
                            <MdOutlinePostAdd className='vitals-preview-header' onClick={(e) => openTodo(e, 'add', null)} style={{width:"17%"}}/>
                            {/* <Button name="submit" label="Add" className="caregiver-add-button" style={{marginRight:"20px", padding:"6px 14px"}} onClick={(e) => openTodo(e, 'add', null)}/> */}
                        </div>
                        <div className='vitals-container'>
                            {allVitalInfoClientList.length > 0 ?
                                allVitalInfoClientList.map((vitals, index) => {
                                    return (
                                        <div style={{cursor:"default"}}>
                                            <span>{vitals.createdDate}</span>
                                            <MdPreview className='vitals-preview' onClick={(e) => openTodo(e, 'view', vitals)}/>
                                            {/* <Button name="submit" label="View" className="caregiver-add-button" onClick={(e) => openTodo(e, 'view', vitals)}/> */}
                                        </div>
                                    )
                                })
                            :
                                <div className='no-task' style={{justifyContent:'center'}}>
                                    <span> No Vitals Available </span>
                                </div>
                            }
                        </div>
                        <Dialog maxWidth="lg" className="popup-subscriber" aria-labelledby="customized-dialog-title" open={showTodoPopup} style={{padding:"16px"}}>
                            <div className="popup-subscriber-header" style={{justifyContent:"center"}}>
                                <div className="popup-title">
                                    {showAdd ? "VIEW VITAL INFORMATION" : "ADD VITAL INFORMATION"}
                                </div>
                            </div>

                            <MuiDialogContent dividers className="popup-body-1" style={{padding:"15px"}}>
                                <div className="label" style={{paddingBottom:"15px", textAlign:'center'}}>
                                    {sessionStorage.getItem('ResidentName')}
                                </div>
                                <div className='pop-row'>
                                    <div className='label col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                                        Report Date & Time <span className='required'>*</span>
                                    </div>

                                    <div className='vital-row col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                                        <div className='answer col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6'>
                                            <CustomTextField style={{width:"100%"}} focused type="date" id="outlined-basic" label='MM/DD/YYYY' variant="outlined" name="date" value={vitalData.date} onChange={updateVitalDetails} disabled={showAdd ? true : false} />
                                        </div>

                                        <div className='answer col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6'>
                                            <CustomTextField style={{width:"100%"}} focused type="time" id="outlined-basic" label='HH:MM' variant="outlined" name="time" value={vitalData.time} onChange={updateVitalDetails} disabled={showAdd ? true : false} />
                                        </div>
                                    </div>
                                </div>
                                <div className='pop-row'>
                                    <div className='label col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                                        Blood Pressure <span className='required'>*</span>
                                    </div>

                                    <div className='vital-row col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                                        <div className='answer col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6'>
                                            <CustomTextField focused id="outlined-basic" label='Left Arm' variant="outlined" helperText='High / Low' name="leftArmBP" value={vitalData.leftArmBP} onChange={updateVitalDetails} disabled={showAdd ? true : false} />
                                        </div>

                                        <div className='answer col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6'>
                                            <CustomTextField focused id="outlined-basic" label='Righ Arm' variant="outlined" helperText='High / Low' name="rightArmBP" value={vitalData.rightArmBP} onChange={updateVitalDetails} disabled={showAdd ? true : false} />
                                        </div>
                                    </div>
                                </div>
                                <div className='pop-row'>
                                    <div className='label col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                                        Heart Rate
                                    </div>

                                    <div className='vital-row col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                                        <div className='answer col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6'>
                                            <CustomTextField focused id="outlined-basic" label='Current rate' variant="outlined" helperText='BPM' name="currentHeartRate" value={vitalData.currentHeartRate} onChange={updateVitalDetails} disabled={showAdd ? true : false} inputProps={{inputMode: "numeric"}} />
                                        </div>

                                        <div className='answer col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6'>
                                            <CustomTextField focused id="outlined-basic" label='Resting rate' variant="outlined" helperText='BPM' name="restingHeartRate" value={vitalData.restingHeartRate} onChange={updateVitalDetails} disabled={showAdd ? true : false} inputProps={{inputMode: "numeric"}}/>
                                        </div>
                                    </div>
                                </div>
                                <div className='pop-row'>
                                    <div className='pop-inner-row'>
                                        <div className='label col-5 col-sm-8 col-md-8 col-lg-6 col-xl-6'>
                                            Body Temperature
                                        </div>

                                        <div className='label col-5 col-sm-8 col-md-8 col-lg-6 col-xl-6'>
                                            Blood Glucose
                                        </div>
                                    </div>

                                    <div className='vital-row col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                                        <div className='answer col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6'>
                                            <CustomTextField focused id="outlined-basic" variant="outlined" helperText='Fahrenheit' name="bodyTemp" value={vitalData.bodyTemp} onChange={updateVitalDetails} disabled={showAdd ? true : false} inputProps={{inputMode: "numeric"}}/>
                                        </div>

                                        <div className='answer col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6'>
                                            <CustomTextField focused id="outlined-basic" variant="outlined" helperText='mmol/L' name="bloodSugar" value={vitalData.bloodSugar} onChange={updateVitalDetails} disabled={showAdd ? true : false} inputProps={{inputMode: "numeric"}}/>
                                        </div>
                                    </div>
                                </div>
                                <div className='pop-row'>
                                    <div className='label col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                                        Oxygen Level
                                    </div>

                                    <div className='vital-row col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                                        <div className='answer col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                                            <CustomTextField focused id="outlined-basic" variant="outlined" helperText='%' style={{ width: "100%" }} name="oxygenLevel" value={vitalData.oxygenLevel} onChange={updateVitalDetails} disabled={showAdd ? true : false} inputProps={{inputMode: "numeric"}}/>
                                        </div>
                                    </div>
                                </div>
                            </MuiDialogContent>
                            <div className="btn-div">
                                {!showAdd ?
                                    <Button name="submit" label="Save" className={showButtonState ? "disabled caregiver-submit-button" : "caregiver-submit-button"} onClick={handleSubmit} disabled={showButtonState}/>
                                    : null}
                                <Button name="cancel" label={showAdd ? "Close" : "Cancel"} className="caregiver-cancel-button" onClick={handleClose} style={showAdd ? {padding:"4px 14px"} : {padding:"0px 8px"}}/>
                            </div>
                        </Dialog>
                    </div>
                </div>
            </div>
            {/* <Footer /> */}
        </>
    );
}

export default Vitals;

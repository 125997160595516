import React, { useState, useEffect } from 'react';
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import { IoIosCloseCircle } from "react-icons/io";

function AlertMassage({ id, message, status, closeAlert, origin }) {

    return (
        <div>
            <Snackbar
                id={id}
                className={status == 200 ? "success" : "error"}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: origin
                }}
                open={true}
                autoHideDuration={50000}
                onClose={closeAlert}
                variant="success"
                ContentProps={{
                    "aria-describedby": "message-id"
                }}
                message={message}
                action={[
                    <IconButton key="close" onClick={closeAlert}>
                        <IoIosCloseCircle />
                    </IconButton>
                ]}
            />
        </div>
    );
}

export default AlertMassage;
import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from './Button';
import { IoIosCloseCircle } from "react-icons/io";

const Confirmation = (props) => {

	return (
		<Dialog className="popup" open={props.open} aria-labelledby="form-dialog-title">
			<DialogContent>
				{props.title ? 
					<>
						<div className='caregiver-header'>
							<div className="caregiver-title">
								{props.title}
							</div>
							<IoIosCloseCircle className="close-button" onClick={props.closeConfirmation} />
						</div>
						<div className="caregiver-content">
							{props.description}
						</div>
						<DialogContentText style={{marginBottom:"8px"}}>
							{props.content}
						</DialogContentText>
					</>
				:
					<>
						<DialogContentText>
							{props.content}
						</DialogContentText>
						<div className="caregiver-contents" style={{textAlign:"center"}}>
							{props.username}
						</div>
					</>
				}
			</DialogContent>
			<DialogActions>
				<Button label="YES" onClick={props.processCallback} className="confirm-success-button" />
				<Button label="NO" onClick={props.closeConfirmation} className="confirm-cancel-button" />
			</DialogActions>
		</Dialog>
	);
};

export default Confirmation;
import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/system';

const CustomTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#FCB040',
    fontWeight: 'bold'
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#B2BAC2',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#FCB040',
    },
    '&:hover fieldset': {
      borderColor: '#B2BAC2',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#FCB040',
    },
  },
});

const Text = (props) => {

  return (
    <>
      <CustomTextField 
        value={props.value} 
        name={props.name} 
        label={props.label} 
        variant="outlined" 
        onChange={props.onChange}
        inputProps={{
          maxLength: props.maxLength ? props.maxLength : 75,
          inputMode: props.inputMode
        }} 
        required 
        focused 
        />
    </>
  );
}

export default Text;
import React from 'react'

const Button = (props) => {
  return (
    <>
      <button
        name={!props.name ? "logIn" : props.name}
        type="submit"
        className={!props.className ? "" : props.className}
        onClick={props.onClick}
        disabled={props.disabled}
        style={props.style}
      >
        {props.label}
      </button>
    </>
  );
}
export default Button;
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./pages/Login";
import Home from "./pages/Home";
import CaregiverTask from "./pages/CaregiverTask";
import CheckIn from "./pages/CheckIn";
import ResidentHome from "./pages/ResidentHome";
import ResidentTask from "./pages/ResidentTask";
import Vitals from "./pages/Vitals";

function RoutesSetting() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        {/* <Route index element={<Login />} /> */}
        <Route path="/home" element={<Home />} />
        <Route path="/caregiver-task" element={<CaregiverTask />} />
        <Route path="/checkin" element={<CheckIn />} />
        <Route path="/resident-home" element={<ResidentHome />} />
        <Route path="/resident-task" element={<ResidentTask />} />
        <Route path="/vitals" element={<Vitals />} />
      </Routes>
    </BrowserRouter>
  );
}

export default RoutesSetting;

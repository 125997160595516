import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { FaRegCircle } from "react-icons/fa";
import { FaCheckCircle } from "react-icons/fa";
import Confirmation from '../common/components/Confirmation';
import * as APIService from "../common/api/index.js";
import URL from "../common/api/ConstantURL";
import AlertMessage from "../common/components/AlertMessage";
import Backdrop from '@mui/material/Backdrop';
import { IoChevronBackCircleSharp } from "react-icons/io5";

const CaregiverTask = () => {
  const [value, setValue] = useState(0);
  const [showTodoPopup, setShowTodoPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alertStatus, setAlertStatusBase] = useState("");
  const [unacknowledgedCaregiverChecklist, setUnacknowledgedCaregiverChecklist] = useState([]);
  const [acknowledgedCaregiverChecklist, setAcknowledgedCaregiverChecklist] = useState([]);
  const [caregiverChecklistId, setCaregiverChecklistId] = useState(0);
  const [caregiverChecklistTitle, setCaregiverChecklistTitle] = useState("");
  const [caregiverChecklistDescription, setCaregiverChecklistDescription] = useState("");
  const [caregiverChecklistTaskType, setCaregiverChecklistTaskType] = useState("");

  useEffect(() => {
    getAllUnackChecklistOfCaregiver();
  }, []);

  const getAllUnackChecklistOfCaregiver = () => {
    setUnacknowledgedCaregiverChecklist([]);
    setLoading(true);
    APIService.getData(URL.getAllUnackChecklistOfCaregiver + '?caregiverId=' + sessionStorage.getItem("CaregiverId"))
      .then(responseData => {
        // Handle response data
        if (responseData.code == 200) {
          setUnacknowledgedCaregiverChecklist(responseData.data);
        } else {
          setAlertStatusBase({ message: responseData.message, status: responseData.code, origin: "center" });
        }
        setLoading(false);
      })
      .catch(error => {
        // Handle errors
        setLoading(false);
        setAlertStatusBase({ message: 'Error while fetch unacknowledged checklist of caregiver', status: '400', origin: "center" });
      });
  }

  const getAllAckChecklistOfCaregiver = () => {
    setAcknowledgedCaregiverChecklist([]);
    setLoading(true);
    APIService.getData(URL.getAllAckChecklistOfCaregiver + '?caregiverId=' + sessionStorage.getItem("CaregiverId"))
      .then(responseData => {
        // Handle response data
        if (responseData.code == 200) {
          setAcknowledgedCaregiverChecklist(responseData.data);
        } else {
          setAlertStatusBase({ message: responseData.message, status: responseData.code, origin: "center" });
        }
        setLoading(false);
      })
      .catch(error => {
        // Handle errors
        setLoading(false);
        setAlertStatusBase({ message: 'Error while fetch unacknowledged checklist of caregiver', status: '400', origin: "center" });
      });
  }


  const navigate = useNavigate();
  const handleChange = (event, newValue) => {
    console.log("newValue", newValue)
    setValue(newValue);
    if (newValue == 0) {
      getAllUnackChecklistOfCaregiver();
    } else {
      getAllAckChecklistOfCaregiver();
    }
  }

  const openTodo = (e, checklist) => {
    setCaregiverChecklistId(checklist.checklistId);
    setCaregiverChecklistTitle(checklist.title);
    setCaregiverChecklistDescription(checklist.description);
    setCaregiverChecklistTaskType(checklist.taskType);
    setShowTodoPopup(true);
  }

  const closeTodoConfirmation = () => {
    setCaregiverChecklistId(0);
    setCaregiverChecklistDescription("");
    setShowTodoPopup(false);
  }

  const confirmationCallback = (e) => {
    updateCaregiverChecklist(e);
  }

  const updateCaregiverChecklist = (e) => {
    e.preventDefault();
    setLoading(true);
    const data = { caregiverId: sessionStorage.getItem("CaregiverId"), checklistId: caregiverChecklistId, taskType: caregiverChecklistTaskType, checklistResponseFlag: (value == 0 ? 1 : 0) };

    APIService.postData(URL.upateResponseOfCaregiverChecklist, data)
      .then(responseData => {
        // Handle response data
        setLoading(false);
        if (responseData.code == 200) {
          closeTodoConfirmation();
          setAlertStatusBase({ message: responseData.message, status: responseData.code, origin: "center" });
          if (value == 0) {
            getAllUnackChecklistOfCaregiver();
          } else {
            getAllAckChecklistOfCaregiver();
          }
        } else {
          setAlertStatusBase({ message: responseData.message, status: responseData.code, origin: "center" });
        }
      })
      .catch(error => {
        // Handle errors
        setLoading(false);
        setAlertStatusBase({ message: (value == 0 ? 'Error while acknowledge client checklist' : "Error while acknowledge client checklist"), status: '400', origin: "center" });
      });
  }

  const closeAlert = () => {
    setAlertStatusBase("");
  }

  const handleBack = () =>{
    navigate("/home");
  }

  return (
    <>
      <Header />
      <div className='home-container'>
        <Backdrop
          sx={{ color: '#ffba30', zIndex: (theme) => theme.zIndex.drawer + 1000 }}
          open={loading}
        >
          <img src={require('../assets/btTree.png')} className='loading-img' />
        </Backdrop>
        {alertStatus ? <AlertMessage status={alertStatus.status} message={alertStatus.message} origin={alertStatus.origin} closeAlert={closeAlert} /> : null}
        <div className='home-inner-container' style={{ justifyContent: "flex-start" }}>
          <div className='heading vitals-heading'> 
            <IoChevronBackCircleSharp className='vitals-preview-header' onClick={handleBack}/>
            <span style={{fontWeight:'bold'}}>Caregiver Task</span>
            <span></span>
          </div>
          <Box className="tab col-11 col-sm-11 col-md-8 col-lg-4 col-xl-4">
            <Tabs
              onChange={handleChange}
              value={value}
              aria-label="Tabs where selection follows focus"
              selectionFollowsFocus
            >
              <Tab value={0} label="To-Do" />
              <Tab value={1} label="Completed" />
            </Tabs>
          </Box>
          <div className='task-container col-11 col-sm-11 col-md-8 col-lg-4 col-xl-4'>
            {value == 0 ?
              (unacknowledgedCaregiverChecklist.length > 0 ?
                unacknowledgedCaregiverChecklist.map((checkList, index) => {
                  return (
                    <div onClick={(e) => openTodo(e, checkList)}>
                      <FaRegCircle />
                      <span>{checkList.title}</span>
                    </div>
                  )
                })
                :
                <div className='no-task'>
                  <span> No Task Available </span>
                </div>
              )

              :
              (acknowledgedCaregiverChecklist.length > 0 ?
                acknowledgedCaregiverChecklist.map((checkList, index) => {
                  return (
                    <div onClick={(e) => openTodo(e, checkList)}>
                      <FaCheckCircle className='check-circle' />
                      <span>{checkList.title}</span>
                    </div>
                  )
                })
                :
                <div className='no-task'>
                  <span> No Task Available </span>
                </div>
              )
            }
            <Confirmation
              title={caregiverChecklistTitle}
              description={caregiverChecklistDescription}
              content={value == 0 ? "Have you completed this task?" : "Are you sure you want to move this task to To-Do?"}
              open={showTodoPopup}
              closeConfirmation={(data) => closeTodoConfirmation(data)}
              processCallback={(data) => confirmationCallback(data)}
            />
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
}

export default CaregiverTask;

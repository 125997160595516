import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { FaUserNurse } from "react-icons/fa6";
import Text from '../common/components/Text';
import Button from '../common/components/Button';
import * as APIService from "../common/api/index.js";
import URL from "../common/api/ConstantURL";
import AlertMessage from "../common/components/AlertMessage";
import Backdrop from '@mui/material/Backdrop';

const Login = () => {
    const navigate = useNavigate();
    const [loginData, setLoginData] = useState({
        email:"",
        loginCode: ""
    });
    const [loading, setLoading] = useState(false);
    const [alertStatus, setAlertStatusBase] = useState("");
    const [loginBtnEnabled, setLoginBtnEnabled] = useState(true);

    useEffect(() => {
        if(sessionStorage.getItem("CaregiverId") && sessionStorage.getItem("ResidentId")){
            navigate("/resident-home");
        }else if(sessionStorage.getItem("CaregiverId")){
            navigate("/home");
        }
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const data = { email: loginData.email, loginCode: loginData.loginCode };

        APIService.postData(URL.caregiverLogin, data)
            .then(responseData => {
                // Handle response data
                if (responseData.code == 200) {
                    // sessionStorage.setItem("ResidentInfo", JSON.stringify(responseData.data.ResidentInfo));
                    sessionStorage.setItem("CaregiverId", responseData.data.caregiverInfo.caregiverId);
                    sessionStorage.setItem("CaregiverCode", responseData.data.caregiverInfo.code);
                    sessionStorage.setItem("CaregiverFirstname", responseData.data.caregiverInfo.firstname);
                    sessionStorage.setItem("CaregiverLastname", responseData.data.caregiverInfo.lastname);
                    navigate("/home");
                } else {
                    setAlertStatusBase({ message: responseData.message, status: responseData.code, origin: "center" });
                }
                setLoading(false);
            })
            .catch(error => {
                // Handle errors
                setLoading(false);
                setAlertStatusBase({ message: 'Error while login caregiver', status: '400', origin: "center" });
            });
    }

    const closeAlert = () => {
        setAlertStatusBase("");
    }

    const updateDetails = (e) => {
        var key = e.target.name;
        var value = e.target.value;
        value = value.replace(/^\s+/, '');
        
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if(key=="email"){
            value = value.replace(/[^0-9a-zA-Z@,.\-_/\s]/g, "");
            if(emailRegex.test(value) && loginData.loginCode.length == 4){
                setLoginBtnEnabled(false);
            }else{
                setLoginBtnEnabled(true);
            }
        }else if(key=="loginCode"){
            value = value.replace(/[^0-9]/g, '')        
            if(emailRegex.test(loginData.email) && value.length == 4){
                setLoginBtnEnabled(false);
            }else{
                setLoginBtnEnabled(true);
            }
        }
        setLoginData({
            ...loginData,
            [key]: value
        });
    };

    return (
        <div className='outer-container'>
            <Backdrop
                sx={{ color: '#ffba30', zIndex: (theme) => theme.zIndex.drawer + 1000 }}
                open={loading}
            >
                <img src={require('../assets/btTree.png')} className='loading-img' />
            </Backdrop>
            {alertStatus ? <AlertMessage status={alertStatus.status} message={alertStatus.message} origin={alertStatus.origin} closeAlert={closeAlert} /> : null}
            <div className='inner-container col-12 col-sm-12 col-md-10 col-lg-8 col-xl-8'>
                <div className='caregiver-login'>
                    <img src={require('../assets/Loginpage_logo.png')} alt="key" />
                    <div className='caregiver-container col-11 col-sm-11 col-md-6 col-lg-6 col-xl-6'>
                        <div className='caregiver-img-container col-11 col-sm-11 col-md-8 col-lg-8 col-xl-8'>
                            <FaUserNurse className="caregiver-icon" />
                        </div>
                        <form onSubmit={handleSubmit} className="caregiver-login-form col-10 col-sm-10 col-md-8 col-lg-8 col-xl-8">
                            <div className="row">
                                <Text
                                    value={loginData.email}
                                    name="email"
                                    label="Email"
                                    onChange={updateDetails}
                                    // autofocus={showAutoFocusStatus}
                                    // helperText={showEmailError?showEmailErrorText:""}
                                    // error={showEmailError}
                                    // onFocus={removeEmailError}
                                    required
                                />
                            </div>
                            <div className="row">
                                <Text
                                    value={loginData.loginCode}
                                    name="loginCode"
                                    label="Login Code"
                                    inputMode="numeric"
                                    onChange={updateDetails}
                                    maxLength="4"
                                    // autofocus={showAutoFocusStatus}
                                    // helperText={showEmailError?showEmailErrorText:""}
                                    // error={showEmailError}
                                    // onFocus={removeEmailError}
                                    required
                                />
                            </div>
                            <div className="row">
                                <Button name="submit" label="Login" className={loginBtnEnabled ? "disabled caregiver-submit-button" : "caregiver-submit-button"} style={{padding:"10px"}} disabled={loginBtnEnabled}/>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
